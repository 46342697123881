var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('div',{staticClass:"d-flex align-items-center line-height-1 pb-2"},[_c('i',{staticClass:"text-primary ti ti-user small-gap-right"}),_c('h4',{staticClass:"text-primary mb-0"},[_vm._v("Daftar Guru")])]),_c('div',[_c('b-row',[_c('b-col',{attrs:{"lg":"1"}},[_c('b-form-select',{staticClass:"w-auto mr-1",attrs:{"options":_vm.options},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1),_c('b-col'),_c('b-col',[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Filter")]),_c('b-form-input',{staticClass:"d-inline-block mr-1 search-min-width",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('b-button',{staticClass:"d-flex small-gap-right",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.downloadData()}}},[(!_vm.isButtonExportLoading)?_c('i',{staticClass:"ti ti-file-export small-gap-right"}):_vm._e(),(_vm.isButtonExportLoading)?_c('b-spinner',{staticClass:"small-gap-right",attrs:{"small":""}}):_vm._e(),_vm._v(" Export ")],1)],1)])],1)],1),_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"totalRows":_vm.total,"pagination-options":{
              enabled: true,
              perPage: _vm.serverParams.per_page,
            },"isLoading":_vm.isLoading,"sort-options":{
              enabled: true,
              initialSortBy: {
                field: _vm.serverParams.order_field,
                type: _vm.serverParams.order_direction,
              },
            },"line-numbers":true},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{staticClass:"mx-1",attrs:{"variant":"light-primary","src":props.row.avatar ? props.row.avatar : null,"text":_vm.avatarText(props.row.name)}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.name))])],1):(props.column.field === 'classroom')?_c('span',{staticClass:"d-flex justify-content-start"},[(props.row.classroom.length > 0)?_c('div',_vm._l((props.row.classroom),function(classroom){return _c('b-badge',{key:classroom.id,staticClass:"small-gap-right small-gap-bottom text-white",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.pascalCase(classroom.name))+" ")])}),1):_vm._e(),(props.row.classroom.length === 0)?_c('div',[_c('small',[_vm._v("Belum Punya Kelas")])]):_vm._e()]):(props.column.field === 'action')?_c('span',{staticClass:"d-flex justify-content-start"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"warning","size":"sm","to":{
                    name: _vm.checkAuthorizeRole('teachers-edit'),
                    params: { id: props.row.id },
                  }}},[_c('i',{staticClass:"ti ti-pencil"})]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.handleDelete(props.row)}}},[_c('i',{staticClass:"ti ti-trash"})])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('b-form-select',{staticClass:"w-auto mr-1",attrs:{"hidden":"","options":_vm.options},on:{"input":function($event){return props.perPageChanged({ currentPerPage: _vm.pageLength })}},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing "+_vm._s(_vm.pageLength <= props.total ? _vm.pageLength * _vm.currentPage : props.total)+" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":_vm.serverParams.page,"total-rows":_vm.total,"per-page":_vm.serverParams.per_page,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) {
                        _vm.currentPage = value;
                        props.pageChanged({ currentPage: value });
                      }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)])],1)],1),_c('teacher-import-modal',{on:{"refresh-table":_vm.loadItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }